import React from "react"
import {Link} from "gatsby"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../common/layout/layout"

const Success = () => {
  return (
    <Layout>
    <div className='success_wrapper'>
      <h1>Thank you! You have successfully joined our Newsletter!</h1>
      <Link to={'/'} className='success_btn'>Home<FontAwesomeIcon icon={faAngleRight} /></Link>
    </div>
    </Layout>
  )
}

export default Success